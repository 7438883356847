import { Button, Container } from '@react-email/components';
import { LPLogo } from './components';
import { EmailTemplateBase } from './types';

function formatCurrency(amount: number, withCents = false): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: withCents ? 2 : 0,
    maximumFractionDigits: withCents ? 2 : undefined,
  }).format(amount);
}

function Content(props: EmailTemplateStripeInvoiceProps) {
  return (
    <div
      style={{
        color: 'black',
        background: '#101012',
        width: '100%',
        minHeight: '100vh',
        padding: '32px 0',
      }}
    >
      <Container
        style={{
          margin: '0 auto',
          paddingLeft: '12px',
          paddingRight: '12px',
          maxWidth: '460px',
        }}
      >
        <LPLogo />

        <div className='bg-white p-8 rounded-xl mt-8'>
          <div className='text-[#7a7a7a]'>Invoice from Luna Park</div>
          <div className='text-[36px] font-semibold my-1'>
            {formatCurrency(props.total / 100, true)}
          </div>
          <hr className='my-4' />
          <div className='my-1'>
            <span className='text-[#7a7a7a]'>To:</span>
            <span className='text-black ml-2'>{props.payer}</span>
          </div>
          <div className='my-1'>
            <span className='text-[#7a7a7a]'>From:</span>
            <span className='text-black ml-2'>Luna Park</span>
          </div>
          <Button
            href={props.hostedInvoiceURL}
            target='_blank'
            style={{
              width: '536px',
              padding: '12px 20px',
              background: '#fbb707',
              color: 'black',
              textAlign: 'center',
              marginTop: '16px',
              borderRadius: '4px',
              fontWeight: 'medium',
            }}
          >
            Pay this invoice
          </Button>
        </div>
      </Container>
    </div>
  );
}

export interface EmailTemplateStripeInvoiceProps {
  invoiceId: string;
  invoiceNumber: string;
  hostedInvoiceURL: string;
  payer: string;
  total: number;
  test: boolean;
}

export const EmailTemplateStripeInvoice: EmailTemplateBase<EmailTemplateStripeInvoiceProps> =
  {
    Key: 'stripe-invoice',
    Name: 'Stripe Invoice',
    Description: '',
    Subject: (props) =>
      `${props.test ? 'TEST - ' : ''}New invoice from Luna Park #${
        props.invoiceNumber
      }`,
    Content,
    PreviewProps: {
      invoiceId: '878c3095-18d4-41ca-aa43-38ee97de56c0',
      invoiceNumber: '#A0387A25-0001',
      hostedInvoiceURL: '',
      total: 12345,
      payer: 'Jialin Tian',
      test: true,
    },
  };
