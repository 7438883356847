import { EmailTemplateLayout, LPLogo, LunaParkFooter } from './components';
import { Button, Text } from '@react-email/components';
import { btnPrimary } from './components/styles';
import { EmailTemplateBase } from './types';

function EmailContentIntakeFormEdit(props: EmailTemplateIntakeProps) {
  return (
    <EmailTemplateLayout>
      <LPLogo />

      <Text className="text-sm">Hi {props.firstName},</Text>
      <Text className="text-xl font-bold">
        It’s time to customize your event!
      </Text>
      <Text className="text-sm mt-10 w-2/3">
        Your host and producer have been confirmed! So let’s gather a bit more
        information from you so we can tailor the event to your team.
      </Text>
      <Button
        href={props.formLink}
        target="_blank"
        style={{
          ...btnPrimary,
          width: '200px',
          padding: '12px 20px',
        }}
      >
        Customize Event
      </Button>

      <Text>Melanie</Text>
      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateIntakeProps {
  firstName: string;
  formLink: string;
}

export const EmailTemplateIntakeFormEdit: EmailTemplateBase<EmailTemplateIntakeProps> =
  {
    Key: 'intake-form-edit',
    Name: 'Intake Form Edit',
    Description: 'Customize your upcoming Luna Park event',
    Subject: () => 'Important: Customizing Your Upcoming Luna Park Event',
    Content: EmailContentIntakeFormEdit,
    PreviewProps: {
      firstName: 'there',
      formLink: 'https://example.com/form',
    },
  };

function EmailContentIntakeFormReceived(props: EmailTemplateIntakeProps) {
  return (
    <EmailTemplateLayout>
      <LPLogo />

      <Text className="text-sm">Hi {props.firstName},</Text>
      <Text className="text-xl font-bold">
        Your Intake Form has been received!
      </Text>
      <Text className="text-sm mt-10 w-2/3">
        Want to update your submission? You can do this anytime up to 24 hours
        ahead of your event. Just click the button below:
      </Text>
      <Button
        href={props.formLink}
        target="_blank"
        style={{
          ...btnPrimary,
          width: '200px',
          padding: '12px 20px',
        }}
      >
        Edit Form
      </Button>

      <Text>Melanie</Text>
      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export const EmailTemplateIntakeFormReceived: EmailTemplateBase<EmailTemplateIntakeProps> =
  {
    Key: 'intake-form-received',
    Name: 'Intake Form Received',
    Description: 'Confirmation of your Luna Park event intake form',
    Subject: () => 'Luna Park Event Intake Confirmation',
    Content: EmailContentIntakeFormReceived,
    PreviewProps: {
      firstName: 'there',
      formLink: 'https://example.com/form',
    },
  };

export interface EmailTemplateIntakeFormSummaryItem {
  label: string;
  value: string;
}

export interface EmailTemplateIntakeFormSummaryProps {
  subject: string;
  items: EmailTemplateIntakeFormSummaryItem[];
}

export function EmailContentIntakeFormSummary(
  props: EmailTemplateIntakeFormSummaryProps,
) {
  return (
    <EmailTemplateLayout>
      {props.items.map((item, i) => (
        <div key={i} className="my-5">
          <div className="text-sm font-bold">{item.label}</div>
          <div style={{ fontSize: '13px', whiteSpace: 'pre-line' }}>
            {item.value}
          </div>
        </div>
      ))}
    </EmailTemplateLayout>
  );
}

export const EmailTemplateIntakeFormSummary: EmailTemplateBase<EmailTemplateIntakeFormSummaryProps> =
  {
    Key: 'intake-form-summary',
    Name: 'Intake Form Summary',
    Description: 'Summary of your Luna Park event intake form',
    Subject: (props) => props.subject,
    Content: EmailContentIntakeFormSummary,
    PreviewProps: {
      subject: 'Ahri - Spotify - 4/20/23 - Studio A',
      items: [
        {
          label: 'Host',
          value: 'Ahri',
        },
        {
          label: 'Studio',
          value: 'Studio A',
        },
      ],
    },
  };
