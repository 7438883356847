import { Button, Img, Text } from '@react-email/components';
import { EmailTemplateLayout } from './components/Layout';
import { btnPrimary } from './components/styles';
import { LunaParkFooter } from './components/LPFooter';
import { EmailTemplateBase } from './types';

export function Content(props: EmailTemplateInvitationProps): JSX.Element {
  return (
    <EmailTemplateLayout
      style={{
        maxWidth: '420px',
      }}
    >
      <Img
        src='https://assets.golunapark.com/static/images/email/invitation-banner.png'
        alt='Luna Park'
        style={{
          width: '100%',
          height: 'auto',
          borderRadius: '12px',
        }}
      />

      <Text>Hi {props.firstName},</Text>
      <Text>
        You&apos;ve been invited to join the rest of your team on Luna Park.
      </Text>
      <Text>Activate your account instantly by clicking the link below.</Text>

      <Button
        href={props.link}
        style={{
          ...btnPrimary,
          width: '240px',
          padding: '12px 20px',
        }}
        target='_blank'
      >
        Activate Account
      </Button>

      <Text>
        <span style={{ fontStyle: 'italic' }}>
          Warning: You&apos;ll gain access to hundreds of fun social games!
        </span>
        &nbsp;🤫
      </Text>
      <Text>
        If the button above doesn&apos;t work, paste this link into your web
        browser:&nbsp;
        <a href={props.link}>{props.link}</a>
      </Text>

      <Text>
        <strong>What is Luna Park?</strong>
        <br />
        Luna Park is a social game platform comprised of live and on demand
        experiences to help you connect and collaborate with your co-workers
        while having tons of fun! We&apos;ve got everything from arcade style
        games to ice breakers to wordplay games!
      </Text>

      <Text>
        Have fun, <br />
        Team Luna Park
      </Text>

      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateInvitationProps {
  firstName: string;
  link: string;
  invitedBy: string;
}

export const EmailTemplateInvitation: EmailTemplateBase<EmailTemplateInvitationProps> =
  {
    Key: 'invitation',
    Name: 'Invitation',
    Description: 'Invitation to join Luna Park',
    Subject: (props) =>
      `${props.invitedBy} invited you to Join Luna Park 🎡 [Activate Your Account]`,
    Content,
    PreviewProps: {
      firstName: 'there',
      link: 'https://example.com/invitation',
      invitedBy: 'John Doe',
    },
  };
