import { Button, Text } from '@react-email/components';
import { EmailTemplateLayout, LPLogo, LunaParkFooter } from './components';
import { EmailTemplateBase } from './types';

export interface EmailTemplateStripeInvoicePaidProps {
  firstName: string;

  invoiceId: string;

  invoiceNumber: string;

  payerEmail: string;

  scheduleLink: string;

  gamePackName: string;

  eventDate?: string;
}

function Content(props: EmailTemplateStripeInvoicePaidProps) {
  return (
    <EmailTemplateLayout>
      <LPLogo />

      <Text>
        <b>👋 Hi {props.firstName},</b>
      </Text>
      <Text>
        <b>
          Great news! Your invoice for {props.gamePackName} has been paid by{' '}
          {props.payerEmail}
        </b>
      </Text>
      <Text>
        This means you’re all set for your event
        {props.eventDate ? ` on ${props.eventDate}` : ''}. The final step is to
        create an invite for your team so that everyone can get in. You can do
        that by clicking the button below
      </Text>
      <div
        style={{
          width: '100%',
          margin: '40px auto',
        }}
      >
        <Button
          style={{
            background: '#FF0935',
            color: '#FFFFFF',
            fontFamily: 'Arial',
            fontSize: `13px`,
            fontWeight: 500,
            borderRadius: '8px',
            letterSpacing: '0.75px',
            width: '300px',
            margin: '8px auto',
            padding: '20px 12px',
            textAlign: 'center',
            display: 'block',
          }}
          href={props.scheduleLink}
        >
          📆 Invite Your Team
        </Button>
      </div>

      <div
        style={{
          width: '100%',
        }}
      >
        <Text
          style={{
            margin: '0 auto',
            display: 'block',
            textAlign: 'center',
          }}
        >
          ✨ Have fun,
          <br />
          Team Luna Park
        </Text>
      </div>

      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export const EmailTemplateStripeInvoicePaid: EmailTemplateBase<EmailTemplateStripeInvoicePaidProps> =
  {
    Key: 'stripe-invoice-paid',
    Name: 'Stripe Invoice Paid',
    Description: '',
    Subject: (props) => `Finalize your ${props.gamePackName} event`,
    Content,
    PreviewProps: {
      firstName: 'there',
      gamePackName: 'First Time Game',
      payerEmail: 'jialin@lunapark.com',
      invoiceId: '',
      invoiceNumber: '',
      scheduleLink: '',
      eventDate: '3/25',
    },
  };
