import { Img } from '@react-email/components';

export function LPLogo(props: {
  width?: number;
  height?: number;
  abbr?: boolean;
  style?: React.CSSProperties;
}): JSX.Element {
  const { width, height, abbr, style } = props;

  if (abbr) {
    return (
      <Img
        src='https://assets.golunapark.com/static/images/lp-logo.png'
        alt='Luna Park'
        width={width ?? 60}
        height={height ?? 60}
        style={style}
      />
    );
  }

  return (
    <Img
      src='https://assets.golunapark.com/static/images/emails/luna-park-logo.png'
      alt='Luna Park'
      width={width ?? 120}
      height={height ?? 'auto'}
      style={style}
    />
  );
}
