import { Button, Img, Section, Text } from '@react-email/components';
import {
  EmailTemplateLayout,
  FooterWithUnsubscribe,
  LPLogo,
} from './components';
import pluralize from 'pluralize';
import { format, formatDistance } from 'date-fns';
import { EmailTemplateBase } from './types';

function formatSocialTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const arr = [];
  if (hours > 0) {
    arr.push(`${hours.toLocaleString()} ${pluralize('hr', hours)}`);
  }
  if (minutes > 0) {
    arr.push(pluralize('min', minutes, true));
  }
  return arr.join(' ');
}

function formatPlayerNames(players: string[]) {
  if (players.length === 1) {
    return players[0];
  }
  if (players.length <= 5) {
    return `${players.slice(0, -1).join(', ')} and ${players.slice(-1)}`;
  }
  return `${players.slice(0, 4).join(', ')} and ${players.length - 4} others`;
}

function Summary(props: { socialSeconds: number; gamePlayedCount: number }) {
  const { socialSeconds, gamePlayedCount } = props;

  return (
    <Section
      style={{
        backgroundImage:
          'url(https://assets.golunapark.com/static/images/emails/analytics-digest/summary-bg.png)',
        backgroundColor: 'black',
        backgroundSize: 'cover',
        width: '100%',
        maxWidth: '600px',
        minWidth: '320px',
        borderRadius: '20px',
        textAlign: 'center',
        padding: '30px 0',
      }}
    >
      <div>
        <Img
          style={{
            display: 'inline',
            verticalAlign: 'middle',
            marginRight: '8px',
          }}
          src='https://assets.golunapark.com/static/images/emails/analytics-digest/ily.png'
          width={40}
          height={40}
        />
        <span
          style={{
            verticalAlign: 'middle',
            color: '#FBB707',
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '40px',
          }}
        >
          {formatSocialTime(socialSeconds)}
        </span>
      </div>

      <Text
        style={{
          color: 'white',
          fontSize: '16px',
          margin: 0,
        }}
      >
        total quality time
      </Text>

      <p style={{ height: '20px', margin: 0 }}></p>

      <div>
        <span
          style={{
            verticalAlign: 'middle',
            color: '#FBB707',
            fontSize: '32px',
            lineHeight: '40px',
            fontWeight: 700,
          }}
        >
          🤝 {gamePlayedCount.toLocaleString()}
        </span>
      </div>

      <Text
        style={{
          color: 'white',
          fontSize: '16px',
          margin: 0,
        }}
      >
        total experiences!
      </Text>
    </Section>
  );
}

function GameSessionView(props: { session: GameSession; index: number }) {
  const { session } = props;

  return (
    <Section>
      <Img
        src={session.groupPhotoUrl}
        alt=''
        style={{
          width: '100%',
          maxWidth: '800px',
          height: 'auto',
          margin: '0 auto',
          borderRadius: '8px',
        }}
      />
      <Text>
        {formatPlayerNames(session.players)} played{' '}
        <strong>{session.gamePackName}</strong>{' '}
        {session.isLive ? '(Live Game)' : ''} on{' '}
        {format(new Date(session.playedTime), 'MMMM d, yyyy')} for{' '}
        <strong>{formatDistance(0, session.durationSec * 1000)}</strong>
      </Text>
    </Section>
  );
}

function Content(props: EmailTemplateAnalyticsDigestProps) {
  return (
    <EmailTemplateLayout style={{ textAlign: 'center', maxWidth: '1124px' }}>
      <LPLogo
        style={{
          margin: '0 auto',
        }}
      />

      <Text>
        <strong>👋 Hi {props.firstName},</strong>
      </Text>

      <div style={{ margin: '16px 0' }}>
        {props.orgIconUrl && (
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginRight: '8px',
              backgroundColor: 'black',
              borderRadius: '50%',
            }}
          >
            <Img
              src={props.orgIconUrl}
              width={40}
              height={40}
              style={{
                borderRadius: '4px',
              }}
            />
          </div>
        )}
        <Text style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          The {props.orgName} team 💗s Luna Park!
        </Text>
      </div>

      <div style={{ margin: '16px 0' }}>
        <Img
          style={{
            display: 'inline',
            verticalAlign: 'middle',
            marginRight: '4px',
          }}
          src='https://assets.golunapark.com/static/images/emails/analytics-digest/magnifier.png'
          width={36}
          height={36}
        />

        <Text style={{ display: 'inline', verticalAlign: 'middle' }}>
          <strong>Here’s what’s been happening on Luna Park</strong>
        </Text>
      </div>

      <Summary
        socialSeconds={props.socialSeconds}
        gamePlayedCount={props.gamePlayedCount}
      />

      <div style={{ margin: '16px 0' }}>
        <Img
          src='https://assets.golunapark.com/static/images/emails/analytics-digest/camera.png'
          width={36}
          height={36}
          style={{
            display: 'inline',
            verticalAlign: 'middle',
          }}
        />
        <Text
          style={{
            display: 'inline',
            verticalAlign: 'middle',
          }}
        >
          <strong>Recent Luna Park Hang Sessions</strong>
        </Text>
      </div>

      {props.gameSessions.map((session, index) => (
        <GameSessionView key={index} session={session} index={index} />
      ))}

      {props.moreSessionsCount > 0 && (
        <Text style={{ marginBottom: 0 }}>
          <strong>
            And {props.moreSessionsCount} other{' '}
            {pluralize('session', props.moreSessionsCount)}!
          </strong>
        </Text>
      )}
      <Button
        style={{
          background: '#FF0935',
          color: '#FFFFFF',
          fontFamily: 'Arial',
          fontSize: `13px`,
          fontWeight: 500,
          borderRadius: '8px',
          letterSpacing: '0.75px',
          width: '300px',
          margin: '8px 0',
          padding: '20px 12px',
        }}
        href={props.analyticsLink}
      >
        See All Sessions
      </Button>

      <Text>
        ✨ Have fun,
        <br />
        Team Luna Park
      </Text>

      <FooterWithUnsubscribe notificationLink={props.notificationLink} />
    </EmailTemplateLayout>
  );
}

export interface GameSession {
  isLive: boolean;
  durationSec: number;
  players: string[];
  gamePackName: string;
  playedTime: string;
  groupPhotoUrl: string;
}

export interface EmailTemplateAnalyticsDigestProps {
  firstName: string;
  orgName: string;
  orgIconUrl?: string;
  socialSeconds: number;
  gamePlayedCount: number;
  gameSessions: GameSession[];
  moreSessionsCount: number;
  notificationLink: string;
  analyticsLink: string;
}

const PreviewProps: EmailTemplateAnalyticsDigestProps = {
  firstName: 'there',
  orgName: 'Luna Park',
  orgIconUrl:
    'https://assets.golunapark.com/images/19395/b98e134d92d345afa1807b3bfa4e582c_sm.png',
  socialSeconds: 10000000,
  gamePlayedCount: 4056,
  notificationLink: 'https://lunapark.com',
  analyticsLink: 'https://lunapark.com',
  moreSessionsCount: 10,

  gameSessions: [
    {
      players: ['John', 'Jane'],
      gamePackName: 'Over-Roasted',
      playedTime: '2023-06-23',
      groupPhotoUrl:
        'https://assets.golunapark.com/generated/5b783c4af1ddb5a0d4ba0d785158d7e1.gif',
      isLive: true,
      durationSec: 50 * 60,
    },
  ],
};

export const EmailTemplateAnalyticsDigest: EmailTemplateBase<EmailTemplateAnalyticsDigestProps> =
  {
    Key: 'analytics-digest',
    Name: 'Analytics Digest',
    Description: `- Frequency: 1st of every month
- Target: Org Admins
- Condition: If the organization has at least 1 valid game session in the past month
`,
    Subject: (props) =>
      `${props.orgName} Updates ${format(new Date(), 'MMMM do, yyyy')}`,
    Content,
    PreviewProps,
  };
