import { Button, Img, Section, Text } from '@react-email/components';
import {
  EmailTemplateLayout,
  FooterWithUnsubscribe,
  LPLogo,
} from './components';
import { EmailTemplateBase } from './types';

function GameCard(props: { game: PromotedGamePack }) {
  const { game } = props;

  return (
    <Section
      style={{
        margin: '20px 0',
      }}
    >
      <Text style={{ marginTop: 0 }}>
        <strong>{game.title}</strong>
      </Text>

      <Text
        style={{
          whiteSpace: 'pre-line',
        }}
      >
        {game.description}
      </Text>

      <ul style={{ listStyle: 'none', margin: '16px 0', padding: 0 }}>
        {game.details.map((detail, i) => (
          <li key={i} style={{ margin: 0, padding: 0 }}>
            <Text style={{ margin: 0 }}>{detail}</Text>
          </li>
        ))}
      </ul>

      {game.contentWarning && (
        <Text>
          <em>{game.contentWarning}</em>
        </Text>
      )}

      {game.coverImageURL && (
        <Img
          src={game.coverImageURL}
          alt={game.title}
          width={'100%'}
          height={'auto'}
          style={{
            margin: '16px auto',
          }}
        />
      )}

      <Section
        style={{
          textAlign: 'center',
        }}
      >
        <Button
          style={{
            background: '#FF0935',
            color: '#FFFFFF',
            fontFamily: 'Arial',
            fontSize: `13px`,
            fontWeight: 500,
            borderRadius: '8px',
            letterSpacing: '0.75px',
            width: '75%',
            padding: '12px 12px',
          }}
          href={game.customPrimaryAction?.link ?? game.scheduleLink}
        >
          {game.customPrimaryAction?.text ??
            (game.isLaunched ? '🗓️ Schedule' : '🗓️ Preschedule Today!')}
        </Button>
      </Section>
    </Section>
  );
}

function Content(props: EmailTemplateGameUpdatesProps): JSX.Element {
  return (
    <EmailTemplateLayout>
      <LPLogo />

      <Text>👋 Hi {props.firstName},</Text>
      <Text>Here are a few games we thought you’d enjoy!</Text>

      {props.games.map((promotion) => (
        <GameCard key={promotion.id} game={promotion} />
      ))}

      <Text
        style={{
          textAlign: 'center',
          marginTop: '64px',
        }}
      >
        ✨ Have fun,
        <br />
        Team Luna Park
      </Text>

      <FooterWithUnsubscribe
        notificationLink={props.notificationSettingsLink}
      />
    </EmailTemplateLayout>
  );
}

export interface PromotedGamePack {
  id: string;
  title: string;
  description: string;
  details: string[];
  contentWarning?: string;
  coverImageURL: string;
  scheduleLink: string;
  isLaunched: boolean;
  customPrimaryAction?: {
    text: string;
    link: string;
  };
}

export interface EmailTemplateGameUpdatesProps {
  firstName: string;
  games: PromotedGamePack[];
  notificationSettingsLink: string;
}

export function formatEmailSubjectGameUpdates(
  props: EmailTemplateGameUpdatesProps
) {
  return `NEW on LP: ${props.games[0]?.title}`;
}

export const EmailTemplateGameUpdates: EmailTemplateBase<EmailTemplateGameUpdatesProps> =
  {
    Key: 'game-updates',
    Name: 'Game Updates',
    Description: '',
    Subject: (props) => `NEW on LP: ${props.games[0]?.title}`,
    Content,
    PreviewProps: {
      firstName: 'there',
      notificationSettingsLink: 'https://www.lunapark.com',
      games: [
        {
          id: '1',
          title: 'Get your BUZZ on with Over Roasted',
          description:
            'In Over-Roasted you’ll put your culinary skills to the test as you take on the role of baristas in a coffee shop partnering to prepare yummy orders by adding ingredients like coffee beans, matcha, caramel, and more. The goal is to prepare as many cups as possible under the time limit. Happy roasting!',
          details: ['👤 2+ players', '🕘 4 minutes', '😅 Difficulty: Medium'],
          isLaunched: true,
          coverImageURL:
            'https://assets.golunapark.com/images/19529/730c3ad68dd54671ae1c5aba74d4abd0.gif',
          scheduleLink: 'https://lunapark.com/schedule',
        },
        {
          id: '2',
          title: 'Collaboration is key in Built For Fun!',
          description:
            'It’s time to build. No, not a building. You are going to be building relationships with your teammates! Cheesy? Maybe. True? Absolutely ; )',
          details: ['👤 2+ players', '🕘 4 minutes'],
          contentWarning:
            'Certain game elements may be challenging for non-native English speakers or those with less US cultural fluency.',
          isLaunched: false,
          coverImageURL:
            'https://assets.golunapark.com/images/19420/ac8c9fa412ef4f159b706c53b5cfca07.jpg',
          scheduleLink: 'https://lunapark.com/schedule',
        },
      ],
    },
  };
