import { LunaParkFooter } from './components';
import { LPLogo } from './components/LPLogo';
import { Img, Text } from '@react-email/components';
import { EmailTemplateLayout } from './components/Layout';
import { EmailTemplateBase } from './types';

function Content(props: EmailTemplateGroupPhotoProps) {
  return (
    <EmailTemplateLayout>
      <LPLogo />

      <Text>Hi {props.firstName},</Text>
      <Text>Thanks for connecting with your team on Luna Park!</Text>
      <Text>
        Below you can find a shareable memory from your game. If you have any
        feedback on your experience just respond to this email.
      </Text>
      <Text>
        You can see the scoreboard and download other pics like this on the{' '}
        <a href={props.memoriesPageURL}>Memories Page for your game</a>!
      </Text>

      <Img
        src={props.groupPhotoURL}
        alt='Group Joy Capture'
        width={'100%'}
        height={'auto'}
      />

      <Text>
        See you at the Park 😉,
        <br />
        The Luna Park Team
      </Text>

      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateGroupPhotoProps {
  firstName: string;
  memoriesPageURL: string;
  groupPhotoURL: string;
}

export const EmailTemplateGroupPhoto: EmailTemplateBase<EmailTemplateGroupPhotoProps> =
  {
    Key: 'group-photo',
    Name: 'Post Game Group Photo',
    Description: `- Frequency: After each game session (1 day cooldown)
- Target: Game organizer
- Condition: if the game session has at least 2 players
`,
    Subject: () => 'Shareable Memory from Luna Park 📸',
    Content,
    PreviewProps: {
      firstName: 'there',
      memoriesPageURL: 'https://www.lunapark.com',
      groupPhotoURL:
        'https://assets.golunapark.com/generated/5b783c4af1ddb5a0d4ba0d785158d7e1.gif',
    },
  };
