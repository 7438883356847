import {
  Button,
  Column,
  Heading,
  Img,
  Row,
  Section,
} from '@react-email/components';
import { EmailTemplateLayout, btnBlack } from './components';
import { getStaticAssetPath } from './utils';
import { utcToZonedTime, format, formatInTimeZone } from 'date-fns-tz';
import { EmailTemplateBase } from './types';

type NotifyType = 'invitation' | 'cancellation' | 'update';

function castNotifyType(val: string) {
  return val as unknown as NotifyType;
}

export function formatEmailSubjectOnDEvent(props: EmailTemplateOnDEventProps) {
  const { isOrganizer } = props;
  const notifyType = castNotifyType(props.notifyType);
  let status = '';
  switch (notifyType) {
    case 'invitation':
      status = 'Invitation';
      break;
    case 'update':
      status = 'Updated event';
      break;
    case 'cancellation':
      status = 'Canceled event';
      break;
    default:
      break;
  }

  const time = formatInTimeZone(
    props.eventStartAt,
    props.eventTimezone,
    'MMM d, yyyy h:mma z'
  );
  if (isOrganizer) {
    return `${status}: 🎡 Your Luna Park Game is scheduled for ${time}`;
  } else {
    return `${status}: 🎡 Luna Park Game with ${props.organizerName} @ ${time}`;
  }
}

function Header(props: EmailTemplateOnDEventProps) {
  const { isOrganizer } = props;
  const notifyType = castNotifyType(props.notifyType);
  let text = '';
  if (isOrganizer) {
    switch (notifyType) {
      case 'invitation':
        text = '✉ Your Experience is Scheduled!';
        break;
      case 'cancellation':
        text = 'Your game was canceled.';
        break;
      case 'update':
        text = '✉ Your game was updated!';
        break;
      default:
        break;
    }
  } else {
    switch (notifyType) {
      case 'invitation':
        text = '✉️ You’ve Been Invited!';
        break;
      case 'cancellation':
        text = `${props.organizerName} has canceled this game.`;
        break;
      case 'update':
        text = `✉️ ${props.organizerName} updated details for your Luna Park Game`;
        break;
      default:
        break;
    }
  }
  return (
    <Heading as='h2' style={{ textAlign: 'center' }}>
      {text}
    </Heading>
  );
}

function OrganizerNotice(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  let text: string | null = null;
  switch (notifyType) {
    case 'invitation':
      text = 'Invites were sent to all the guests you added.';
      break;
    case 'update':
      text = 'Update messages were sent to your guests as well.';
      break;
    case 'cancellation':
      text =
        'We’ve let your guests know. We hope to see you back on Luna Park soon!';
      break;
    default:
      break;
  }
  if (text === null) return null;
  return (
    <Heading as='h3' style={{ color: '#FBB707', textAlign: 'center' }}>
      {text}
    </Heading>
  );
}

function ManageEvent(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  if (notifyType === 'cancellation') return null;
  return (
    <Section>
      <div>
        <div
          style={{
            fontSize: '14px',
            display: 'inline-block',
          }}
          className='sm:w-1/2 sm:float-left w-full float-none'
        >
          Need to change the date? Add more people? Update any details? Access
          the run of show?
        </div>
        <div
          style={{
            display: 'inline-block',
          }}
          className='sm:w-1/2 sm:float-right sm:text-right sm:mt-0 w-full float-none text-left mt-1'
        >
          <Button
            href={props.manageLink}
            target='_blank'
            style={{
              ...btnBlack,
              width: '200px',
              padding: '12px 20px',
            }}
          >
            Manage Event
          </Button>
        </div>
      </div>
    </Section>
  );
}

function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0]?.toUpperCase())
    .join('');
}

function OrganizerMessage(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  if (props.message === '' || notifyType === 'cancellation') return null;
  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.20)',
        borderRadius: '8px',
        padding: '14px',
        marginTop: '30px',
      }}
    >
      <div>
        {props.organizerAvatarURL ? (
          <Img
            src={props.organizerAvatarURL}
            alt='avatar'
            width='40'
            height='40'
            style={{ display: 'inline-block' }}
          />
        ) : (
          <span
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              border: '1px solid #CCCCCC',
              backgroundColor: '#333333',
              color: 'white',
              display: 'inline-block',
              textAlign: 'center',
              fontSize: '14px',
              lineHeight: '40px',
            }}
          >
            {getInitials(props.organizerName)}
          </span>
        )}
        <span
          style={{
            marginLeft: '10px',
            height: '40px',
            lineHeight: '40px',
            verticalAlign: 'top',
          }}
        >
          Message from <b>{props.organizerName}</b>
        </span>
      </div>
      <div style={{ marginTop: '12px', fontSize: '14px' }}>{props.message}</div>
    </div>
  );
}

function FirstTimeNotice(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  if (notifyType === 'cancellation') return null;
  return (
    <Section style={{ marginTop: '30px' }}>
      <div
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          display: 'inline-block',
          height: '30px',
          lineHeight: '30px',
        }}
        className='sm:w-1/2 sm:float-left w-full float-none'
      >
        First time on Luna Park?
      </div>
      <div
        style={{
          display: 'inline-block',
        }}
        className='sm:w-1/2 sm:float-right sm:text-right sm:mt-0 w-full float-none text-left mt-0.5'
      >
        <Button
          href={props.tutorialLink}
          target='_blank'
          style={{
            ...btnBlack,
            width: '200px',
            padding: '12px 20px',
          }}
        >
          Watch this tutorial
        </Button>
      </div>
    </Section>
  );
}

function RequirementItem(props: {
  requirement: { icon: string; text: string };
}) {
  const { requirement } = props;
  return (
    <Row style={{ borderSpacing: '8px 0px' }}>
      {/* <Column
        style={{
          width: '24px',
          height: '24px',
        }}
      >
        <Img
          src={getStaticAssetPath(
            `images/emails/requirements/${requirement.icon}.png`,
          )}
          alt="webcam"
          width="24"
          height="24"
        />
      </Column> */}
      <Column>{requirement.text}</Column>
    </Row>
  );
}

function Requirements(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  if (notifyType === 'cancellation') return null;
  const requirements = [
    {
      first: {
        icon: 'webcam',
        text: 'A laptop or desktop with a webcam (no phones or tablets)',
      },
      second: {
        icon: 'network',
        text: 'A good internet connection',
      },
    },
    {
      first: {
        icon: 'headphones',
        text: 'Use of headphones recommended for the best audio experience',
      },
      second: {
        icon: 'browser',
        text: 'Use Chrome, Firefox, or Microsoft Edge (Safari is not currently supported)',
      },
    },
    {
      first: {
        icon: 'vpn',
        text: 'If applicable, please disconnect from VPN before joining',
      },
    },
  ];
  return (
    <Section style={{ marginTop: '30px' }}>
      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Requirements</div>
      <Section style={{ fontSize: '14px' }}>
        {requirements.map((pair, index) => (
          <div key={index}>
            <div className='inline-block sm:w-1/2 sm:float-left w-full mt-3'>
              <RequirementItem requirement={pair.first} />
            </div>
            <div className='inline-block sm:w-1/2 sm:float-left w-full mt-3'>
              {pair.second && <RequirementItem requirement={pair.second} />}
            </div>
          </div>
        ))}
      </Section>
    </Section>
  );
}

function GameInfo(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  const startAt = utcToZonedTime(props.eventStartAt, props.eventTimezone);
  const endAt = utcToZonedTime(props.eventEndAt, props.eventTimezone);
  return (
    <div style={{ marginTop: '30px' }}>
      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
        {notifyType === 'cancellation'
          ? 'Canceled Game'
          : 'About the Experience'}
      </div>
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.20)',
          borderRadius: '8px',
          padding: '14px',
          marginTop: '8px',
        }}
      >
        <Row style={{ fontSize: '14px', borderSpacing: '8px 0px' }}>
          <Column style={{ width: '160px', height: '90px' }}>
            <Img
              src={props.gamePackCoverURL}
              alt=''
              style={{
                width: '160px',
                height: '90px',
                borderRadius: '4px',
              }}
            />
          </Column>
          <Column>
            <div style={{ lineHeight: '14px' }}>
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {props.gamePackName}
                </div>
                <br />
                <div style={{ marginBottom: '2px' }}>
                  {format(startAt, 'EEE, MM/dd/yy')}
                </div>
                <div>{`${format(startAt, 'h:mm a')} - ${format(
                  endAt,
                  'h:mm a'
                )} ${props.eventTimezone}`}</div>
              </div>
            </div>
          </Column>
        </Row>
        <div style={{ fontSize: '14px', marginTop: '8px' }}>
          {props.gamePackDesc}
        </div>
      </div>
    </div>
  );
}

export function Memories(props: EmailTemplateOnDEventProps) {
  const notifyType = castNotifyType(props.notifyType);
  if (notifyType === 'cancellation') return null;
  return (
    <div style={{ marginTop: '30px' }}>
      <div
        style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px' }}
      >
        Don’t forget your Souvenir at the end of the game
      </div>
      <Img
        src={getStaticAssetPath(`images/emails/memories.png`)}
        alt='memories'
        width='100%'
      />
    </div>
  );
}

export interface EmailTemplateOnDEventProps {
  notifyType: string;

  firstName: string;

  gamePackName: string;

  gamePackDesc: string;

  gamePackCoverURL: string;

  eventStartAt: string;

  eventEndAt: string;

  eventTimezone: string;

  organizerName: string;

  organizerAvatarURL?: string;

  message: string;

  manageLink: string;

  tutorialLink: string;

  isOrganizer: boolean;

  ics: string;
  icsMethod: string;
}

function Content(props: EmailTemplateOnDEventProps): JSX.Element {
  return (
    <EmailTemplateLayout
      style={{
        maxWidth: '980px',
        margin: '0 auto',
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
    >
      <Header {...props} />
      {props.isOrganizer && <OrganizerNotice {...props} />}
      {props.isOrganizer && <ManageEvent {...props} />}
      <OrganizerMessage {...props} />
      <FirstTimeNotice {...props} />
      <Requirements {...props} />
      <GameInfo {...props} />
      {/* <Memories {...props} /> */}
    </EmailTemplateLayout>
  );
}

export const EmailTemplateOnDEvent: EmailTemplateBase<EmailTemplateOnDEventProps> =
  {
    Key: 'ond-event',
    Name: 'OnD Event',
    Description: 'Event notification for OnD',
    Subject: formatEmailSubjectOnDEvent,
    Content,
    PreviewProps: {
      firstName: 'there',
      gamePackName: 'The Seasoning (Full Version)',
      gamePackCoverURL:
        'https://assets.golunapark.com/images/19689/2da3030300ee48998f6b379560745b41_md.png',
      eventStartAt: '2023-12-19 09:30:00Z',
      eventEndAt: '2023-12-19 10:30:00Z',
      eventTimezone: 'Asia/Shanghai',
      manageLink:
        'http://localhost:8080/events/2900edd1-ab17-417a-84c0-58c99884924b',
      ics: '',
      icsMethod: 'REQUEST',
      notifyType: 'invitation',
      gamePackDesc:
        'Season’s greetings, everyone! In these holiday games, we’re bringing a little something for everyone. Songs, puzzles, even some clips from holiday episodes of your favorite shows. Just a little bit of seasoning to enjoy the most wonderful time of the year!',
      organizerName: 'Jialin',
      organizerAvatarURL:
        'https://avatars.slack-edge.com/2022-10-13/4214948288243_8dd7dec40c3d09690a68_512.png',
      message: "Let's have fun!",
      tutorialLink: 'https://youtu.be/i-kk2hoy9R0',
      isOrganizer: true,
    },
  };
