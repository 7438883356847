import {
  EmailTemplateLayout,
  LPLogo,
  LunaParkFooter,
  btnPrimary,
} from './components';
import { Button, Text } from '@react-email/components';
import { EmailTemplateBase } from './types';

function Content(props: EmailTemplateLoginVerifyProps) {
  return (
    <EmailTemplateLayout
      style={{
        maxWidth: '420px',
      }}
    >
      <LPLogo />

      <Text>
        Click the link below to sign in to your Luna Park account.
        <br />
        This link will expire in 1 hour and can only be used once.
      </Text>

      <Button
        href={props.link}
        target='_blank'
        style={{
          ...btnPrimary,
          margin: '16px 0',
          width: '240px',
          padding: '12px 20px',
        }}
      >
        Login to Luna Park
      </Button>

      <Text style={{ margin: '48px 0' }}>
        If the button above doesn’t work, paste this link into your web
        browser:&nbsp;
        <a href={props.link}>{props.link}</a>
      </Text>

      <Text
        style={{
          color: '#AAAAAA',
          margin: '64px 0',
        }}
      >
        If you did not make this request, you can safely ignore this email.
      </Text>

      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateLoginVerifyProps {
  link: string;
}

export const EmailTemplateLoginVerify: EmailTemplateBase<EmailTemplateLoginVerifyProps> =
  {
    Key: 'login-verify',
    Name: 'Login Verify',
    Description: 'Verify your Luna Park login',
    Subject: () => 'Sign in to Luna Park',
    Content,
    PreviewProps: {
      link: 'https://example.com/login/verify',
    },
  };
