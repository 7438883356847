import { Img, Text } from '@react-email/components';
import { EmailTemplateLayout, LunaParkFooter } from './components';
import { getStaticAssetPath } from './utils';
import { EmailTemplateBase } from './types';

function Content(props: EmailTemplateWelcomeBySelfRegisterProps) {
  return (
    <EmailTemplateLayout>
      <Img
        src={getStaticAssetPath('images/emails/welcome-banner.png')}
        alt='welcome'
        width='100%'
        height='auto'
      />
      <Text>Hi {props.name},</Text>

      <Text>
        You’ve been granted access to join the rest of your team on Luna Park.
      </Text>

      <Text>Activate your account instantly by clicking the link below.</Text>

      <a href={props.activateLink} style={{ color: '#01ACC4' }}>
        <strong>Activate Account</strong>
      </a>

      <Text style={{ fontStyle: 'italic' }}>
        Warning: You’ll gain access to hundreds of fun social games! 🤫
      </Text>

      <Text>
        If the button above doesn’t work, paste this link into your web browser:
      </Text>
      <a href={props.activateLink}>{props.activateLink}</a>
      <Text />
      <Text>
        <strong>What is Luna Park?</strong>
        <br />
        Luna Park is a social game platform comprised of live and on demand
        experiences to help you connect and collaborate with your co-workers
        while having tons of fun! We’ve got everything from arcade style games
        to ice breakers to trivia games!
      </Text>
      <Text>
        Have fun,
        <br />
        Team Luna Park
      </Text>
      <LunaParkFooter />
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateWelcomeBySelfRegisterProps {
  name: string;
  activateLink: string;
}

export const EmailTemplateWelcomeBySelfRegister: EmailTemplateBase<EmailTemplateWelcomeBySelfRegisterProps> =
  {
    Key: 'welcome-by-self-register',
    Name: 'Welcome by Self Register',
    Description: 'Welcome email for self-registered users',
    Subject: () => `Welcome to Luna Park 🎡 [Activate Your Account]`,
    Content,
    PreviewProps: {
      name: 'there',
      activateLink: 'https://example.com/activate-account',
    },
  };
