import {
  EnumsMessageDistributionChannel,
  EnumsMessageTemplateFormat,
  type RenderSlackBlocksParams,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import {
  HARDCODED_EMAIL_TEMPLATES,
  HARDCODED_SLACK_TEMPLATES,
} from './Hardcoded';

export type MessageTemplateSettings = {
  id: string;
  key: string;
  name: string;
  description: string;
  format: EnumsMessageTemplateFormat;
  distributionChannel: EnumsMessageDistributionChannel;
};

export class MessageTemplateSettingsUtils {
  static FormatHardCodedEmailId(key: string) {
    return `hardcoded-email-${key}`;
  }

  static FormatHardCodedSlackId(key: string) {
    return `hardcoded-slack-${key}`;
  }

  static GetHardcodedEmailTemplate(id: string) {
    return HARDCODED_EMAIL_TEMPLATES.find(
      (t) => this.FormatHardCodedEmailId(t.Key) === id
    );
  }

  static async GetAllMessageTemplateSettings(): Promise<
    MessageTemplateSettings[]
  > {
    const results: MessageTemplateSettings[] = [];

    for (const template of HARDCODED_EMAIL_TEMPLATES) {
      results.push({
        id: this.FormatHardCodedEmailId(template.Key),
        key: template.Key,
        name: template.Name,
        description: template.Description,
        format: EnumsMessageTemplateFormat.MessageTemplateFormatHardcoded,
        distributionChannel:
          EnumsMessageDistributionChannel.MessageDistributionChannelEmail,
      });
    }

    for (const template of HARDCODED_SLACK_TEMPLATES) {
      results.push({
        id: this.FormatHardCodedSlackId(template.key),
        key: template.key,
        name: template.name,
        description: template.description,
        format: EnumsMessageTemplateFormat.MessageTemplateFormatHardcoded,
        distributionChannel:
          EnumsMessageDistributionChannel.MessageDistributionChannelSlack,
      });
    }

    const resp = await apiService.message.queryTemplateSettings();
    for (const settings of resp.data.settingsList) {
      results.push({
        id: settings.id,
        key: settings.key,
        name: settings.name,
        description: settings.description,
        format: EnumsMessageTemplateFormat.MessageTemplateFormatMarkdown,
        distributionChannel:
          settings.template.type ||
          EnumsMessageDistributionChannel.MessageDistributionChannelEmail,
      });
    }

    return results.sort((a, b) => a.name.localeCompare(b.name));
  }

  static async GetMessageTemplateSettings(id: string) {
    const list = await this.GetAllMessageTemplateSettings();
    return list.find((s) => s.id === id);
  }

  static async OpenSlackBlocksEditor(key: string) {
    for (const template of HARDCODED_SLACK_TEMPLATES) {
      if (template.key !== key) continue;

      const blocks = await MessageTemplateSettingsUtils.GetSlackBlocks(
        template.slackTemplateName
      );
      if (!blocks) continue;

      window.open(
        `https://app.slack.com/block-kit-builder/T04CF55GQHH#${encodeURIComponent(
          blocks
        )}`
      );
      return;
    }
  }

  static async GetSlackBlocks(
    templateName: RenderSlackBlocksParams['templateName']
  ) {
    const resp = await apiService.message.renderSlackBlocks({
      templateName,
    });
    return resp.data.blocks;
  }
}
