import { Container, Link, Section, Text } from '@react-email/components';
import { LPLogo } from './LPLogo';
import { paragraph } from './styles';

export function LunaParkFooter() {
  return (
    <Container
      style={{
        marginTop: '32px',
      }}
    >
      <LPLogo
        abbr
        style={{
          margin: '0 auto',
        }}
      />
      <Text
        style={{
          ...paragraph,
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <a href='https://lunapark.com'>lunapark.com</a>
      </Text>
    </Container>
  );
}

export function FooterWithUnsubscribe(props: {
  notificationLink: string;
  noLogo?: boolean;
}) {
  return (
    <Section
      style={{
        textAlign: 'center',
        margin: '32px 0',
      }}
    >
      {!props.noLogo && (
        <div style={{ margin: '16px 0' }}>
          <LPLogo
            abbr
            width={32}
            height={32}
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          />

          <Text
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: 0,
            }}
          >
            <a href='https://lunapark.com' target='_blank' rel='noreferrer'>
              lunapark.com
            </a>
          </Text>
        </div>
      )}

      <Link href={props.notificationLink}>
        <Text
          style={{
            color: '#AAAAAA',
            textAlign: 'center',
            fontSize: '12px',
          }}
        >
          Unsubscribe
        </Text>
      </Link>
    </Section>
  );
}
