import { Text, Button } from '@react-email/components';
import { EmailTemplateLayout, LPLogo, btnDelete } from './components';
import { getStaticAssetPath } from './utils';
import { EmailTemplateBase } from './types';

export interface EmailTemplateExportSessionProps {
  firstName: string;
  orgName: string;
  downloadLink: string;
}

function Content(props: EmailTemplateExportSessionProps): JSX.Element {
  return (
    <EmailTemplateLayout style={{ textAlign: 'center' }}>
      <LPLogo
        style={{
          margin: '0 auto',
        }}
      />

      <img
        src={getStaticAssetPath('images/export-session-email-illustration.png')}
        width='250px'
        height='230px'
        style={{ margin: '20px auto 10px auto' }}
      />

      <Text className='text-xl font-bold'>Download the exported session</Text>
      <Text
        style={{
          fontSize: '13px',
          lineHeight: '150%',
        }}
      >
        Click the button below to download the exported session. <br />
        It will be available for 7 days.
      </Text>
      <Button
        href={props.downloadLink}
        target='_blank'
        style={{
          ...btnDelete,
          width: '300px',
          padding: '12px 20px',
          margin: '20px auto',
        }}
      >
        Download
      </Button>
      <hr />
      <Text
        style={{
          fontSize: '11px',
          lineHeight: '150%',
        }}
      >
        Luna Park helps boost team culture with Events, Icebreakers, DEI
        Celebrations and more
      </Text>
      <Text
        style={{
          fontSize: '11px',
          lineHeight: '150%',
          color: '#8B9294',
        }}
      >
        All rights reserved.
      </Text>
      <Text
        style={{
          fontSize: '13px',
          lineHeight: '150%',
        }}
      >
        LunaPark.com
      </Text>
    </EmailTemplateLayout>
  );
}

export const EmailTemplateExportSession: EmailTemplateBase<EmailTemplateExportSessionProps> =
  {
    Key: 'export-session',
    Name: 'Export Session',
    Description: '',
    Subject: (props) => `Exported Sessions for ${props.orgName}`,
    Content,
    PreviewProps: {
      firstName: 'there',
      orgName: 'Luna Park',
      downloadLink: 'https://www.lunapark.com',
    },
  };
