export const body: React.CSSProperties = {
  background: 'white',
};

export const container: React.CSSProperties = {
  margin: '0 auto',
  paddingLeft: '12px',
  paddingRight: '12px',
  maxWidth: '444px',
};

export const paragraph: React.CSSProperties = {
  fontSize: '14px',
  fontFamily: 'Arial',
  color: '#000',
};

export const paragraphSecondary: React.CSSProperties = {
  ...paragraph,
  color: '#AAAAAA',
};

export const btnPrimary: React.CSSProperties = {
  fontSize: '16px',
  fontFamily: 'Arial',
  fontWeight: 500,
  backgroundColor: '#01acc4',
  color: '#fff',
  borderRadius: '12px',
  textAlign: 'center',
  letterSpacing: '1.25px',
};

export const btnBlack: React.CSSProperties = {
  fontSize: '16px',
  fontFamily: 'Arial',
  fontWeight: 500,
  backgroundColor: '#232325',
  color: '#fff',
  borderRadius: '12px',
  textAlign: 'center',
  letterSpacing: '1.25px',
};

export const btnDelete: React.CSSProperties = {
  fontSize: '16px',
  fontFamily: 'Arial',
  fontWeight: 500,
  backgroundColor: '#FF0935',
  color: '#fff',
  borderRadius: '12px',
  textAlign: 'center',
  letterSpacing: '1.25px',
};
