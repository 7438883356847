import { Container } from '@react-email/components';

export function EmailTemplateLayout(props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <Container
      style={{
        margin: '0 auto',
        paddingLeft: '12px',
        paddingRight: '12px',
        maxWidth: '460px',
        fontFamily: 'Arial',
        ...props.style,
      }}
    >
      {props.children}
    </Container>
  );
}
